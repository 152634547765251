/*Loading Spinner*/

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  display: none;
  position: relative;
  width: 18px;
  height: 18px;
}
.spinner.loading {
  display: flex;
}
.spinner.dark div {
  border: 2px solid #000;
  border-color: #000 transparent transparent transparent;
}
.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  margin: 2px;
  border-radius: 50%;
  border: 2px solid;
  border-color: #ffffff transparent transparent transparent;

  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}

/*Toggle */
.toggleInput {
  clip: rect(0 0 0 0);
}

.toggle:hover .toggleContainerChecked {
  background: #0f172a !important;
}
.toggle:hover .toggleContainer {
  background: #64748b !important;
}
.toggleCircle {
  box-shadow: 0px 4px 4px rgba(160, 166, 177, 0.24),
    inset 0px -2px 4px rgba(160, 166, 177, 0.08);
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}

.wallpaper-gradient{
  background: radial-gradient(50% 50% at 50% 50%, rgba(15, 23, 42, 0) 0%, rgba(15, 23, 42, 0.3) 100%);
}

.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: rgba(180, 178, 178, 0.19) linear-gradient(to right, rgba(238, 238, 238, 0.84) 8%, rgba(187, 187, 187, 0.75) 18%, rgba(238, 238, 238, 0.8) 33%);
  background-size: 800px 104px;
  height: 70px;
  position: relative;
}
