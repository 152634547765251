@import 'custom.css';
@import './CustomScroll.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/inter400.woff') format('woff');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('../assets/fonts/inter500.woff2') format('woff2'),
      url('../assets/fonts/inter500.woff') format('woff');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('../assets/fonts/inter600.woff2') format('woff2'),
      url('../assets/fonts/inter600.woff') format('woff');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/inter700.woff2') format('woff2'),
      url('../assets/fonts/inter700.woff') format('woff');
  }

  html {
    @apply font-inter text-base-gray;
  }

  body {
    @apply font-inter text-base-gray;
  }

  * {
    -webkit-font-smoothing: antialised;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }
}

@layer components {
  .tooltip {
    @apply z-[100000] flex items-center justify-center rounded-[12px] bg-base-black py-8 px-16 text-text-sm text-base-white max-w-[360px];
  }
}
